import React, { Component } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"
import { Layout, ContactForm } from "../../components"

import {
  Link,
  Banner,
  BlockImageTextHalf,
  TitleH2,
} from "@igloonet-web/shared-ui"

import Map from "../../images/contact/map.jpg"
import PersonSimca from "../../images/team/mini/simca.jpg"
import PersonAdam from "../../images/team/mini/adam.jpg"
import PersonWicki from "../../images/team/mini/wicki.jpg"

const TextBoxImageTopWrapper = styled.div`
  padding: 0 0 3rem;
  border-top: 1px solid ${(props) => props.theme.color.gray300};
  background: ${(props) => props.theme.color.gray200};

  img {
    max-width: 150px;
    border-radius: 4px;
  }
`

const TextBoxKontakt = (props) => (
  <Col xs={6} xl={4} className="d-flex flex-column align-items-center mt-5">
    <img src={props.imageHref} alt={""} loading="lazy" />
    <h3 className="mt-4">{props.city}</h3>
    <div className="mb-2">
      <div>{props.name}</div>
      <div>{props.address}</div>
    </div>
    <div className="mt-auto">
      <div>
        <a href={"tel:" + props.tel}>{props.tel}</a>
      </div>
      <div>
        <a href={"mailto:" + props.email}>{props.email}</a>
      </div>
    </div>
  </Col>
)

class Kontakt extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Kontakt - igloonet | igloonet</title>
          <meta
            name="description"
            content="Sídlíme v Brně-Řečkovicích na Karásku 5. Další kontaktní informace najdete na stránce."
          />
        </Helmet>
        <Banner>
          <h1>Kontakt</h1>
          <p>Stavte se k nám na kávu</p>
        </Banner>

        <TextBoxImageTopWrapper>
          <Container className="text-center">
            <Row>
              <TextBoxKontakt
                imageHref={PersonSimca}
                city="Brno"
                name="Simča Kudlík"
                tel="+420 739 450 400"
                email="info@igloonet.cz"
              />

              <TextBoxKontakt
                imageHref={PersonAdam}
                city="Praha"
                name="Adam Šilhan"
                tel="+420 739 634 302"
                email="adam@igloonet.cz"
              />

              <TextBoxKontakt
                imageHref={PersonWicki}
                city="Hosting"
                name="Martin Taraba"
                tel="+420 777 523 225"
                email="podpora@igloonet.cz"
              />
            </Row>
          </Container>
        </TextBoxImageTopWrapper>

        <Container>
          <BlockImageTextHalf image={Map} alt="Mapa sídla" right>
            <TitleH2>Sídlo firmy</TitleH2>

            <h3>igloonet, s.r.o.</h3>
            <div className="mb-2">Karásek 5, 621 00, Brno</div>
            <div>IČ: 27713482</div>
            <div className="mb-2">DIČ: CZ27713482</div>
            <div className="mb-2">
              zapsaná v OR vedeném KS v Brně, oddíl C, vložka 53913
            </div>
            <div>
              <Link to="/kontakt/bankovni-spojeni">Bankovní spojení </Link> |{" "}
              <Link to="/payment-info">Payment Info</Link>
            </div>
          </BlockImageTextHalf>
        </Container>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Kontakt
